import React from "react"
import { Link } from "gatsby"

import Seo from "../../../components/seo"

export default function ThankYou({ location }) {
  const heroTitle = "Thank you"
  return (
    <>
      <Seo title={heroTitle} />
      <section class="c-section c-section-sm" style={{ backgroundColor: "#eeedff" }}>
        <div class="container">
          <div class="row vh-md-60">
            <div class=" col-12 col-md-9 col-sm-10 mx-auto my-auto text-center">
              <h1 class="h1 h1-sm">Thank you</h1>
              <h2 class="h2 h2-sm">for providing us with the relevant information</h2>
              <p class="lead py-3">
                We will be in touch via email over the next 2 business days.
                {/* location.state?.form === "risk-profile" && "risk profile" */}
                {/* location.state?.form === "5-minute-health-check" && "risk profile" */}
                {/* location.state?.form === "investment-advisory" && "financial health check-up"*/}
              </p>
              <Link
                to="/"
                class="c-button c-button--blue c-button-radius c-button-md d-inline-flex flex-row align-items-center"
              >
                Back to Home
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right ml-2"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
